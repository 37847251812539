(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/normalize-odds.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/normalize-odds.js');
"use strict";


const {
  normalizeToDot
} = svs.components.sportinfo.common.helpers;
const normalizeOdds = odds => {
  const normalized = new Array(3).fill(null);
  if (odds) {
    if (odds.one) {
      normalized[0] = normalizeToDot(odds.one);
    }
    if (odds.x) {
      normalized[1] = normalizeToDot(odds.x);
    }
    if (odds.two) {
      normalized[2] = normalizeToDot(odds.two);
    }
  }
  return normalized;
};
if (svs.isServer) {
  module.exports = normalizeOdds;
} else {
  setGlobal('svs.components.sport.drawsRedux.normalizeOdds', normalizeOdds);
}

 })(window);