(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/funds-adapter.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/funds-adapter.js');
"use strict";


let constants;
if (svs.isServer) {
  constants = require('./constants.es6');
} else {
  constants = svs.components.sport.drawsRedux.constants;
}
const {
  FetchState
} = constants;
if (svs.isServer) {
  module.exports = {
    FetchState
  };
} else {
  const {
    createEntityAdapter
  } = RTK;
  const fundsAdapter = createEntityAdapter({
    selectId: fund => "".concat(fund.productId, "_").concat(fund.drawNumber)
  });
  setGlobal('svs.components.sport.drawsRedux.funds', {
    adapter: fundsAdapter,
    FetchState,
    globalizedSelectors: fundsAdapter.getSelectors(state => state.Funds)
  });
}

 })(window);