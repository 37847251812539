(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/normalize-draws-lite.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/normalize-draws-lite.js');
"use strict";

let constants;
if (svs.isServer) {
  constants = require('./constants.es6');
} else {
  constants = svs.components.sport.drawsRedux;
}
const {
  relatedTypes
} = constants;
const normalizeDrawsLite = function (drawResults, relatedProductId, productId, drawNumber) {
  let type = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : relatedTypes.PIX;
  const Draws = {
    ids: [],
    entities: {},
    relatedDraws: {}
  };
  const generateDrawKey = draw => "".concat(relatedProductId, "_").concat(draw.drawNumber);
  const currentDrawKey = "".concat(productId, "_").concat(drawNumber);
  Draws.relatedDraws[currentDrawKey] = {
    [type]: []
  };
  const parseDraw = draw => {
    const drawKey = generateDrawKey(draw);
    draw.productId = relatedProductId;
    draw.isLite = true;
    delete draw.fund;
    Draws.relatedDraws[currentDrawKey][type].push(drawKey);
    Draws.ids.push(drawKey);
    Draws.entities[drawKey] = draw;
  };
  if (drawResults) {
    for (const drawResult of drawResults) {
      parseDraw(drawResult.payload.draw);
    }
  }
  return {
    Draws
  };
};
if (svs.isServer) {
  module.exports = normalizeDrawsLite;
} else {
  setGlobal('svs.components.sport.drawsRedux.normalizeDrawsLite', normalizeDrawsLite);
}

 })(window);