(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/draws-actions.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/draws-actions.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  createAsyncThunk,
  createAction
} = RTK;
const {
  DrawState
} = svs.components.sport.tipsenShared;
const {
  normalizeDraws,
  FetchState,
  normalizeFunds
} = svs.components.sport.drawsRedux;
const {
  getJackpots
} = svs.content.shared;
const {
  products
} = svs.utils;
const {
  fetchDrawsPromise,
  fetchOpenedDrawsPromise,
  fetchNetSalePromise
} = svs.components.sport.drawsRedux.services;
const {
  processJackpotData
} = svs.content.shared.jackpotHelpers;
const {
  fetchRelatedDraws
} = svs.components.sport.drawsRedux;
const Errors = {
  NoOutdatedDraws: 'NoOutdatedDrawsError'
};
const loadOpenedDraws = createAsyncThunk('loadOpenedDraws', async drawAPIPath => {
  const draws = await fetchOpenedDrawsPromise(drawAPIPath);
  const normalizedDraws = normalizeDraws(draws);
  const Funds = normalizeFunds(draws, undefined);
  return _objectSpread(_objectSpread({}, normalizedDraws), {}, {
    Funds
  });
});
const selectOutdatedDraws = (state, productId, drawNumbers) => drawNumbers.filter(drawNumber => {
  const drawKey = "".concat(productId, "_").concat(drawNumber);
  const draw = state.Draws.entities[drawKey];
  const fetchState = state.Draws.requests[drawKey];
  const lastLoaded = new Date(state.Draws.loaded[drawKey]);
  const isDrawLive = draw && new Date(draw.regCloseTime) < Date.now() && draw.drawStateId > DrawState.Opened && draw.drawStateId < DrawState.WinpayoutStarted;
  const maxDiffSeconds = (isDrawLive ? 1 : 5) * 60;
  let elapsedSeconds;
  if (dateFns.isValid(lastLoaded)) {
    elapsedSeconds = dateFns.differenceInSeconds(new Date(), lastLoaded);
  } else {
    elapsedSeconds = Number.POSITIVE_INFINITY;
  }
  return fetchState !== FetchState.Pending && elapsedSeconds > maxDiffSeconds;
});
const loadDrawsLite = createAsyncThunk('loadDrawsLite', fetchRelatedDraws, {
  condition: (_ref, _ref2) => {
    let {
      relatedProductId
    } = _ref;
    let {
      getState
    } = _ref2;
    const itsProductId = products.getSpecificDrawName(relatedProductId);
    const cacheKey = [itsProductId, 'lite'].join('_');
    const lastLoaded = new Date(getState().Draws.loaded[cacheKey]);
    let elapsedMinutes;
    if (dateFns.isValid(lastLoaded)) {
      elapsedMinutes = dateFns.differenceInMinutes(new Date(), lastLoaded);
    } else {
      elapsedMinutes = Number.POSITIVE_INFINITY;
    }
    const fetchState = getState().Draws.requests[cacheKey];
    if (fetchState === FetchState.Pending || elapsedMinutes < 5) {
      return false;
    }
  }
});
const loadOpenedDrawsByEngine = createAsyncThunk('loadOpenedDrawsByEngine', async engineDefinition => {
  const [draws, jackpots] = await Promise.all([fetchOpenedDrawsPromise(engineDefinition.loadDrawsApiPath), getJackpots(engineDefinition.drawProductIds)]);
  const Funds = normalizeFunds(draws, jackpots);
  const normalizedDraws = normalizeDraws(draws);
  return _objectSpread(_objectSpread({}, normalizedDraws), {}, {
    Funds
  });
}, {
  condition: (engineDefinition, _ref3) => {
    let {
      getState
    } = _ref3;
    const lastLoaded = new Date(getState().Draws.loaded[engineDefinition.loadDrawsApiPath]);
    let elapsedMinutes;
    if (dateFns.isValid(lastLoaded)) {
      elapsedMinutes = dateFns.differenceInMinutes(new Date(), lastLoaded);
    } else {
      elapsedMinutes = Number.POSITIVE_INFINITY;
    }
    const fetchState = getState().Draws.requests[engineDefinition.loadDrawsApiPath];
    if (fetchState === FetchState.Pending || elapsedMinutes < 5) {
      return false;
    }
  }
});
const loadDrawByProduct = createAsyncThunk('loadDrawByProduct', async (_ref4, _ref5) => {
  let {
    productId,
    outdatedDrawNumbers,
    shouldFetchJackpot = false
  } = _ref4;
  let {
    rejectWithValue
  } = _ref5;
  if (!outdatedDrawNumbers.length) {
    return rejectWithValue({
      name: Errors.NoOutdatedDraws
    });
  }
  const [drawsResponses, jackpotResponses] = await fetchDrawsPromise(productId, outdatedDrawNumbers, shouldFetchJackpot);
  const draws = drawsResponses.draws.map(drawsResponse => drawsResponse.draw);
  const jackpots = jackpotResponses.reduce((jackpots, jackpotResponse) => jackpots.concat(processJackpotData([jackpotResponse.jackpot])), []);
  const Funds = normalizeFunds(draws, jackpots);
  const normalizedDraws = normalizeDraws(draws);
  const rejectedDraws = drawsResponses.rejectedDraws.reduce((rejectedDraws, drawsResponse, index) => {
    if (drawsResponse.error) {
      rejectedDraws.push("".concat(productId, "_").concat(outdatedDrawNumbers[index]));
    }
    return rejectedDraws;
  }, []);
  return {
    Funds,
    draws: normalizedDraws,
    rejectedDraws
  };
}, {
  getPendingMeta: (_ref6, _ref7) => {
    let {
      arg
    } = _ref6;
    let {
      getState
    } = _ref7;
    const {
      productId,
      drawNumber
    } = arg;
    const drawNumbers = [].concat(drawNumber);
    const outdatedDrawNumbers = selectOutdatedDraws(getState(), productId, drawNumbers);
    const retainedDrawNumbers = drawNumbers.filter(drawNumber => !outdatedDrawNumbers.includes(drawNumber));
    Object.assign(arg, {
      outdatedDrawNumbers,
      retainedDrawNumbers
    });
  },
  condition: (_ref8, _ref9) => {
    let {
      productId,
      drawNumber
    } = _ref8;
    let {
      getState
    } = _ref9;
    if (!Number(productId)) {
      return false;
    }
    const drawNumbers = [].concat(drawNumber);
    if (!drawNumbers.length || !drawNumbers.every(Number)) {
      return false;
    }
    const fetchState = getState().Draws.requests["".concat(productId, "_").concat(drawNumber)];
    if (fetchState === FetchState.Pending) {
      return false;
    }
  }
});
const updateDraw = createAction('tipsen/updateDraw', function (_ref10) {
  let {
    drawResponse
  } = _ref10;
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return {
    payload: _objectSpread(_objectSpread({}, normalizeDraws([drawResponse])), {}, {
      Funds: normalizeFunds([drawResponse], undefined)
    }),
    meta: _objectSpread({}, options)
  };
});
const loadNetSaleForDraws = createAsyncThunk('loadNetSaleForDraws', async _ref11 => {
  let {
    productId
  } = _ref11;
  const netSaleResponse = await fetchNetSalePromise({
    productId
  });
  return {
    Draws: netSaleResponse
  };
});
setGlobal('svs.components.sport.drawsRedux.constants.Errors', Errors);
setGlobal('svs.components.sport.drawsRedux.actions', {
  loadOpenedDraws,
  loadOpenedDrawsByEngine,
  loadDrawByProduct,
  updateDraw,
  loadDrawsLite,
  loadNetSaleForDraws
});

 })(window);