(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/normalize-funds-lite.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/normalize-funds-lite.js');
"use strict";

let normalizeJackpotHelper;
if (svs.isServer) {
  normalizeJackpotHelper = require('./normalize-jackpot-helper.es6');
} else {
  normalizeJackpotHelper = svs.components.sport.drawsRedux;
}
const {
  normalizeJackpot
} = normalizeJackpotHelper;
const normalizeFundsLite = (drawResults, jackpots, relatedProductId) => {
  const Funds = {
    ids: [],
    entities: {}
  };
  const generateFundKey = draw => "".concat(relatedProductId, "_").concat(draw.drawNumber);
  const parseDraw = draw => {
    const currentFund = {
      drawNumber: draw.drawNumber,
      productId: relatedProductId
    };
    const fundKey = generateFundKey(draw);
    let relatedJackpot;
    let normalizedJackpot;
    if (jackpots.length > 0) {
      relatedJackpot = jackpots.find(jackpot => jackpot.productId === relatedProductId && jackpot.drawNumber === draw.drawNumber);
      const relatedJackpots = [];
      if (relatedJackpot) {
        relatedJackpots.push(relatedJackpot);
      }
      normalizedJackpot = normalizeJackpot(relatedJackpots, draw);
    }
    currentFund.isLite = true;
    if (normalizedJackpot && normalizedJackpot.length > 0) {
      currentFund.jackpots = normalizedJackpot;
    }
    Funds.ids.push(fundKey);
    Funds.entities[fundKey] = currentFund;
  };
  if (drawResults) {
    for (const drawResult of drawResults) {
      parseDraw(drawResult.payload.draw);
    }
  }
  return Funds;
};
if (svs.isServer) {
  module.exports = normalizeFundsLite;
} else {
  setGlobal('svs.components.sport.drawsRedux.normalizeFundsLite', normalizeFundsLite);
}

 })(window);