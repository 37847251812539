(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/draws-services.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/draws-services.js');
"use strict";


const {
  jupiter,
  log
} = svs.core;
const {
  products
} = svs.utils;
const logger = log.getLogger('draws-redux:fetchDrawsPromise');
const fetchOpenedDrawsPromise = drawAPIPath => new Promise((resolve, reject) => {
  jupiter.get("/draw/1/".concat(drawAPIPath, "/draws"), response => {
    resolve(response.draws);
  }, error => {
    reject(error);
  });
});
const fetchDrawsPromise = async function (productId, drawNumber) {
  let shouldFetchJackpot = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const urls = [];
  drawNumber.forEach(draw => {
    urls.push("/draw/1/".concat(products.getSpecificDrawName(productId), "/draws/").concat(draw));
    shouldFetchJackpot && urls.push(encodeURIComponent("/draw/1/jackpot/draws?product=".concat(products.getSpecificDrawName(productId), "&drawNumber=").concat(draw)));
  });
  const response = await jupiter.get(urls);
  const jackpots = [];
  const draws = {
    draws: [],
    rejectedDraws: []
  };
  response.responses.forEach(value => {
    if (value.error) {
      logger.info("Error fetching draws and/or jackpots, error: ".concat(value.error));
      if (response.responses.indexOf(value) % 2 === 0) {
        draws.rejectedDraws.push(value);
      }
    } else if (shouldFetchJackpot && response.responses.indexOf(value) % 2 !== 0) {
      jackpots.push(value);
    } else {
      draws.draws.push(value);
    }
  });
  return [draws, jackpots];
};
const fetchNetSalePromise = async _ref => {
  let {
    productId
  } = _ref;
  const filterIncludes = ['draws.currentNetSale',
  'draws.currentNetSales', 'draws.drawNumber', 'draws.productId'];
  const url = "/draw/1/".concat(products.getDrawName(productId), "/draws/light?include=").concat(filterIncludes.join(','));
  const response = await jupiter.get(url);
  let draws = [];
  if (response.error) {
    logger.info("Error fetching netSales, error: ".concat(response.error));
  } else {
    draws = response.draws;
  }
  return draws;
};
setGlobal('svs.components.sport.drawsRedux.services', {
  fetchDrawsPromise,
  fetchOpenedDrawsPromise,
  fetchNetSalePromise
});

 })(window);