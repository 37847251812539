(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/hooks/use-draw.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/hooks/use-draw.js');
"use strict";


const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  loadDrawByProduct
} = svs.components.sport.drawsRedux.actions;
const {
  selectDraw
} = svs.components.sport.drawsRedux.selectors;
const {
  useEffect
} = React;
const useDraw = function (_ref) {
  let {
    productId,
    drawNumber
  } = _ref;
  let {
    skip = false,
    shouldFetchJackpot = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const dispatch = useDispatch();
  const draw = useSelector(state => selectDraw(state, productId, drawNumber));
  useEffect(() => {
    if (!productId || !drawNumber || skip) {
      return;
    }
    if (!draw || Object.keys(draw).length === 0) {
      dispatch(loadDrawByProduct({
        drawNumber,
        productId,
        shouldFetchJackpot
      }));
    }
  }, [productId, drawNumber, draw, dispatch, skip, shouldFetchJackpot]);
  return draw;
};
setGlobal('svs.components.sport.drawsRedux.hooks.useDraw', useDraw);

 })(window);