(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/draws-adapter.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/draws-adapter.js');
"use strict";

let constants;
if (svs.isServer) {
  constants = require('./constants.es6');
} else {
  constants = svs.components.sport.drawsRedux.constants;
}
const {
  FetchState
} = constants;
const drawSorter = (a, b) => {
  const aReg = new Date(a.regCloseTime);
  const bReg = new Date(b.regCloseTime);
  return aReg > bReg;
};
if (svs.isServer) {
  module.exports = {
    drawSorter,
    FetchState
  };
} else {
  const {
    createEntityAdapter
  } = RTK;
  const drawsAdapter = createEntityAdapter({
    sortComparer: drawSorter,
    selectId: draw => "".concat(draw.productId, "_").concat(draw.drawNumber)
  });
  setGlobal('svs.components.sport.drawsRedux', {
    adapter: drawsAdapter,
    FetchState,
    globalizedSelectors: drawsAdapter.getSelectors(state => state.Draws)
  });
}

 })(window);