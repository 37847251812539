(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/funds-selectors.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/funds-selectors.js');
"use strict";


const {
  selectAll,
  selectById
} = svs.components.sport.drawsRedux.funds.globalizedSelectors;
const generateFundKey = _ref => {
  let {
    productId,
    drawNumber
  } = _ref;
  return "".concat(productId, "_").concat(drawNumber);
};
const selectAllFunds = state => selectAll(state);
const selectFundById = (state, productId, drawNumber) => selectById(state, generateFundKey({
  productId,
  drawNumber
}));
const selectJackpotById = (state, productId, drawNumber) => {
  const currentFund = selectFundById(state, productId, drawNumber);
  const jackpotValues = currentFund !== null && currentFund !== void 0 && currentFund.jackpots ? currentFund === null || currentFund === void 0 ? void 0 : currentFund.jackpots[0] : null;
  return jackpotValues && !jackpotValues.defaultJackpot ? jackpotValues : '';
};
const selectGuaranteedJackpotById = (state, productId, drawNumber) => {
  const currentFund = selectFundById(state, productId, drawNumber);
  const jackpotValues = currentFund !== null && currentFund !== void 0 && currentFund.jackpots ? currentFund === null || currentFund === void 0 ? void 0 : currentFund.jackpots[0] : null;
  return jackpotValues && jackpotValues.defaultJackpot ? jackpotValues : '';
};
const selectExtraMoneyById = (state, productId, drawNumber) => {
  const currentFund = selectFundById(state, productId, drawNumber);
  return currentFund !== null && currentFund !== void 0 && currentFund.extraMoney && parseInt(currentFund.extraMoney, 10) > 0 ? currentFund.extraMoney : undefined;
};
const selectRolloverInById = (state, productId, drawNumber) => {
  const currentFund = selectFundById(state, productId, drawNumber);
  return currentFund !== null && currentFund !== void 0 && currentFund.rolloverIn && parseInt(currentFund.rolloverIn, 10) > 0 ? currentFund.rolloverIn : undefined;
};
const selectJackpotAmountById = (state, productId, drawNumber) => {
  const jackpotValues = selectJackpotById(state, productId, drawNumber);
  return jackpotValues ? jackpotValues.jackpotAmount : 0;
};
setGlobal('svs.components.sport.drawsRedux.selectors', {
  selectAllFunds,
  selectFundById,
  selectJackpotById,
  selectGuaranteedJackpotById,
  selectExtraMoneyById,
  selectRolloverInById,
  selectJackpotAmountById
});

 })(window);