(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/services/get-related-draws.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/services/get-related-draws.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
let products;
let normalizeDrawsLite;
let normalizeFundsLite;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  products = trinidad.components.require('utils', 'products');
  normalizeDrawsLite = require('../normalize-draws-lite.es6');
  normalizeFundsLite = require('../normalize-funds-lite.es6');
} else {
  products = svs.utils.products;
  normalizeDrawsLite = svs.components.sport.drawsRedux.normalizeDrawsLite;
  normalizeFundsLite = svs.components.sport.drawsRedux.normalizeFundsLite;
}
const fetchRelatedDraws = fetcher => req => async _ref => {
  let {
    relatedProductId,
    productId,
    drawNumber,
    type
  } = _ref;
  const itsProductId = products.getSpecificDrawName(relatedProductId);
  const params = new URLSearchParams([['ctx', "draw_".concat(itsProductId)], ['andfilter', ['payload.draw.drawState;Open'].join(',')], ['includes', ['payload.draw.regCloseTime', 'payload.draw.drawComment', 'payload.draw.drawNumber'].join(',')], ['sort', 'payload.draw.regCloseTime']]);
  const jackpotPath = '/draw/1/jackpots';
  const relatedDrawPath = "/search/1/query?".concat(params);
  const [drawData, jackpotData] = await fetcher(relatedDrawPath, jackpotPath, req);
  const Funds = normalizeFundsLite(drawData.result, jackpotData.jackpots ? jackpotData.jackpots : [], relatedProductId);
  const normalizedDraws = normalizeDrawsLite(drawData.result, relatedProductId, productId, drawNumber, type);
  return _objectSpread(_objectSpread({}, normalizedDraws), {}, {
    Funds
  });
};
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  const fetchJackpots = async (jackpotPath, req) => {
    try {
      const jackpotResponse = await trinidad.jupiter.callAsync({
        method: 'GET',
        path: jackpotPath,
        req
      });
      return jackpotResponse;
    } catch (_unused) {
      return [];
    }
  };
  const fetchDraws = async (drawsPath, req) => {
    const drawResponse = await trinidad.jupiter.callAsync({
      method: 'GET',
      path: drawsPath,
      req
    });
    return drawResponse;
  };
  const fetcher = async (drawPath, jackpotPath, req) => {
    const [draws, jackpots] = await Promise.all([fetchDraws(drawPath, req), fetchJackpots(jackpotPath, req)]);
    return [draws, jackpots];
  };
  module.exports = fetchRelatedDraws(fetcher);
} else {
  const {
    jupiterFetch
  } = svs.core;
  const fetcher = async (drawPath, jackpotPath) => {
    const [drawResponse, jackpotResponse] = await Promise.all([jupiterFetch.fetch(drawPath, {
      method: 'GET'
    }), jupiterFetch.fetch(jackpotPath, {
      method: 'GET'
    })]);
    const drawData = await drawResponse.json();
    const jackpotData = await jackpotResponse.json();
    if (!drawResponse.ok) {
      throw drawData;
    }
    return [drawData, jackpotData];
  };
  setGlobal('svs.components.sport.drawsRedux.fetchRelatedDraws', fetchRelatedDraws(fetcher)());
}

 })(window);