(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/constants.js');
"use strict";


const relatedTypes = {
  PIX: 'PIX',
  TRANSFER: 'TRANSFER'
};
const FetchState = {
  Idle: 'Idle',
  Pending: 'Pending',
  Fulfilled: 'Fulfilled',
  Rejected: 'Rejected'
};
const exportObject = {
  relatedTypes,
  FetchState
};
if (svs.isServer) {
  module.exports = exportObject;
} else {
  setGlobal('svs.components.sport.drawsRedux.constants', exportObject);
}

 })(window);