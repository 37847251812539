(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/draws-selectors.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/draws-selectors.js');
"use strict";


const {
  selectEntities
} = svs.components.sport.drawsRedux.globalizedSelectors;
const {
  createSelector
} = RTK;
const selectDraw = (state, productId, drawNumber) => {
  if (!productId || !drawNumber) {
    return undefined;
  }
  const drawKey = "".concat(productId, "_").concat(drawNumber);
  const draw = state.Draws.entities[drawKey] || undefined;
  return draw;
};
const selectDrawsRequests = state => state.Draws.requests;
const makeSelectRegCloseTime = (productId, drawNumber) => state => {
  var _state$Draws$entities;
  const drawKey = [productId, drawNumber].join('_');
  return (_state$Draws$entities = state.Draws.entities[drawKey]) === null || _state$Draws$entities === void 0 ? void 0 : _state$Draws$entities.regCloseTime;
};
const selectRelatedDraws = state => state.Draws.relatedDraws;
const makeSelectRelatedDraw = (productId, drawNumber, type) => createSelector([selectEntities, selectRelatedDraws, makeSelectRegCloseTime(productId, drawNumber)], (drawsEntities, relatedDraws, _regCloseTime) => {
  var _relatedDraws$related;
  const relatedDrawsKey = "".concat(productId, "_").concat(drawNumber);
  const liteDrawsKeys = (relatedDraws === null || relatedDraws === void 0 || (_relatedDraws$related = relatedDraws[relatedDrawsKey]) === null || _relatedDraws$related === void 0 ? void 0 : _relatedDraws$related[type]) || [];
  const relatedDrawsEntities = liteDrawsKeys.map(drawKey => drawsEntities[drawKey]);
  const regCloseTime = new Date(_regCloseTime);
  return relatedDrawsEntities.sort((a, b) => {
    const distanceA = Math.abs(new Date(a.regCloseTime) - regCloseTime);
    const distanceB = Math.abs(new Date(b.regCloseTime) - regCloseTime);
    return distanceA - distanceB;
  })[0];
});
const selectNumberOfEvents = (state, productId, drawNumber) => {
  var _draw$drawEvents;
  if (!productId || !drawNumber) {
    return undefined;
  }
  const draw = selectDraw(state, productId, drawNumber);
  return draw === null || draw === void 0 || (_draw$drawEvents = draw.drawEvents) === null || _draw$drawEvents === void 0 ? void 0 : _draw$drawEvents.length;
};
setGlobal('svs.components.sport.drawsRedux.selectors', {
  selectDraw,
  selectDrawsRequests,
  makeSelectRelatedDraw,
  selectNumberOfEvents
});

 })(window);