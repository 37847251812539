(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/funds-redux.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/funds-redux.js');
"use strict";


const {
  adapter: fundsAdapter
} = svs.components.sport.drawsRedux.funds;
const {
  loadOpenedDrawsByEngine,
  loadDrawByProduct,
  updateDraw,
  loadDrawsLite,
  loadOpenedDraws
} = svs.components.sport.drawsRedux.actions;
const {
  createSlice
} = RTK;
const fundSlice = createSlice({
  name: 'funds',
  initialState: fundsAdapter.getInitialState(),
  extraReducers: builder => {
    builder.addCase(loadOpenedDraws.fulfilled, (state, action) => {
      fundsAdapter.addMany(state, action.payload.Funds.entities);
    }).addCase(updateDraw, (state, action) => {
      fundsAdapter.upsertMany(state, action.payload.Funds.entities);
    }).addCase(loadDrawsLite.fulfilled, (state, action) => {
      fundsAdapter.addMany(state, action.payload.Funds.entities);
    }).addCase(loadDrawByProduct.fulfilled, (state, action) => {
      fundsAdapter.setMany(state, action.payload.Funds.entities);
    }).addCase(loadOpenedDrawsByEngine.fulfilled, (state, action) => {
      fundsAdapter.setMany(state, action.payload.Funds.entities);
    });
  }
});
setGlobal('svs.components.sport.fundsRedux', {
  slice: fundSlice
});

 })(window);