(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/hooks/use-related-draw.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/hooks/use-related-draw.js');
"use strict";


const {
  useDispatch,
  useSelector
} = ReactRedux;
const {
  loadDrawsLite
} = svs.components.sport.drawsRedux.actions;
const {
  useEffect
} = React;
const {
  makeSelectRelatedDraw
} = svs.components.sport.drawsRedux.selectors;
const {
  useMemoizedSelector
} = svs.components.lbUtils.hooks;
const {
  relatedTypes
} = svs.components.sport.drawsRedux.constants;
const useRelatedDraw = function (_ref) {
  let {
    productId,
    relatedProductId,
    drawNumber,
    type = relatedTypes.PIX
  } = _ref;
  let {
    skip = false
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const dispatch = useDispatch();
  const selectRelatedDraws = useMemoizedSelector(makeSelectRelatedDraw, productId, drawNumber, type);
  const relatedDraw = useSelector(state => selectRelatedDraws(state));
  useEffect(() => {
    if (!productId || skip) {
      return;
    }
    if (!relatedDraw || relatedDraw.length === 0) {
      dispatch(loadDrawsLite({
        relatedProductId,
        productId,
        drawNumber,
        type
      }));
    }
  }, [productId, relatedDraw, dispatch, skip, relatedProductId, drawNumber, type]);
  return relatedDraw;
};
setGlobal('svs.components.sport.drawsRedux.hooks.useRelatedDraw', useRelatedDraw);

 })(window);