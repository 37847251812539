(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/normalize-funds.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/normalize-funds.js');
"use strict";

const _excluded = ["fund"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
let normalizeJackpotHelper;
if (svs.isServer) {
  normalizeJackpotHelper = require('./normalize-jackpot-helper.es6');
} else {
  normalizeJackpotHelper = svs.components.sport.drawsRedux;
}
const {
  normalizeJackpot
} = normalizeJackpotHelper;
const normalizeFunds = function (draws) {
  let jackpots = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  const Funds = {
    ids: [],
    entities: {}
  };
  const generateFundKey = draw => "".concat(draw.productId, "_").concat(draw.drawNumber);
  const parseDraw = realDraw => {
    const {
        fund
      } = realDraw,
      draw = _objectWithoutProperties(realDraw, _excluded);
    const currentFund = {
      drawNumber: draw.drawNumber,
      productId: draw.productId
    };
    const fundKey = generateFundKey(draw);
    const normalizedJackpots = normalizeJackpot(jackpots, draw);
    if (normalizedJackpots.length > 0) {
      currentFund.jackpots = normalizedJackpots;
    }
    const fundFromJackpots = normalizedJackpots.find(jackpot => jackpot.windiv === 1);
    if (fundFromJackpots && fundFromJackpots.drawNumber === draw.drawNumber) {
      currentFund.rolloverIn = fundFromJackpots.jackpotAmount;
    }
    if (fund) {
      currentFund.extraMoney = String(fund.extraMoney);
      currentFund.rolloverIn = String(fund.rolloverIn);
    }
    Funds.ids.push(fundKey);
    Funds.entities[fundKey] = currentFund;
  };
  if (draws) {
    for (const draw of draws) {
      parseDraw(draw);
    }
  }
  return Funds;
};
if (svs.isServer) {
  module.exports = normalizeFunds;
} else {
  setGlobal('svs.components.sport.drawsRedux.normalizeFunds', normalizeFunds);
}

 })(window);