(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/draws-redux/assets/javascripts/draws-redux.js') >= 0) return;  svs.modules.push('/components/sport/draws-redux/assets/javascripts/draws-redux.js');
"use strict";


const {
  adapter: drawsAdapter,
  FetchState
} = svs.components.sport.drawsRedux;
const {
  loadOpenedDraws,
  loadOpenedDrawsByEngine,
  loadDrawByProduct,
  updateDraw,
  loadDrawsLite,
  loadNetSaleForDraws
} = svs.components.sport.drawsRedux.actions;
const {
  Errors
} = svs.components.sport.drawsRedux.constants;
const {
  createSlice
} = RTK;
const {
  products
} = svs.utils;
const drawsSlice = createSlice({
  name: 'draws',
  initialState: drawsAdapter.getInitialState({
    loaded: {},
    requests: {},
    relatedDraws: {}
  }),
  extraReducers: builder => {
    builder.addCase(loadDrawByProduct.pending, (state, action) => {
      const {
        productId,
        outdatedDrawNumbers
      } = action.meta.arg;
      outdatedDrawNumbers.forEach(drawNumber => {
        const drawId = "".concat(productId, "_").concat(drawNumber);
        state.requests[drawId] = FetchState.Pending;
      });
    }).addCase(loadDrawByProduct.fulfilled, (state, action) => {
      const {
        draws,
        rejectedDraws
      } = action.payload;
      const cacheTs = new Date().toISOString();
      drawsAdapter.addMany(state, draws.Draws.entities);
      for (const drawId of draws.Draws.ids) {
        state.loaded[drawId] = cacheTs;
        state.requests[drawId] = FetchState.Fulfilled;
      }
      for (const drawId of rejectedDraws) {
        state.requests[drawId] = FetchState.Rejected;
      }
    }).addCase(loadDrawByProduct.rejected, (state, action) => {
      var _action$payload;
      if (((_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.name) === Errors.NoOutdatedDraws) {
        return;
      }
      const {
        productId,
        drawNumber,
        outdatedDrawNumbers
      } = action.meta.arg;
      [].concat(outdatedDrawNumbers || drawNumber).forEach(drawNumber => {
        state.requests["".concat(productId, "_").concat(drawNumber)] = FetchState.Rejected;
      });
    }).addCase(loadOpenedDraws.pending, (state, action) => {
      state.requests[action.meta.arg] = FetchState.Pending;
    }).addCase(loadOpenedDraws.fulfilled, (state, action) => {
      drawsAdapter.addMany(state, action.payload.Draws.entities);
      const cacheTs = new Date().toISOString();
      state.loaded[action.meta.arg] = cacheTs;
      state.requests[action.meta.arg] = FetchState.Fulfilled;
      for (const drawId of action.payload.Draws.ids) {
        state.loaded[drawId] = cacheTs;
        state.requests[drawId] = FetchState.Fulfilled;
      }
    }).addCase(loadOpenedDraws.rejected, (state, action) => {
      state.requests[action.meta.arg] = FetchState.Rejected;
    }).addCase(loadOpenedDrawsByEngine.pending, (state, action) => {
      state.requests[action.meta.arg.loadDrawsApiPath] = FetchState.Pending;
    }).addCase(loadOpenedDrawsByEngine.fulfilled, (state, action) => {
      drawsAdapter.addMany(state, action.payload.Draws.entities);
      const cacheTs = new Date().toISOString();
      state.loaded[action.meta.arg.loadDrawsApiPath] = cacheTs;
      state.requests[action.meta.arg.loadDrawsApiPath] = FetchState.Fulfilled;
      for (const drawId of action.payload.Draws.ids) {
        state.loaded[drawId] = cacheTs;
        state.requests[drawId] = FetchState.Fulfilled;
      }
    }).addCase(loadOpenedDrawsByEngine.rejected, (state, action) => {
      state.requests[action.meta.arg.loadDrawsApiPath] = FetchState.Rejected;
    }).addCase(updateDraw, (state, action) => {
      var _state$entities$drawI, _action$meta;
      const draws = action.payload.Draws;
      const drawId = draws.ids[0];
      const draw = draws.entities[drawId];
      const jackpots = ((_state$entities$drawI = state.entities[drawId]) === null || _state$entities$drawI === void 0 ? void 0 : _state$entities$drawI.jackpots) || [];
      if (jackpots.length > 0) {
        draw.jackpots = jackpots;
      }
      state.requests[drawId] = FetchState.Fulfilled;
      if ((_action$meta = action.meta) !== null && _action$meta !== void 0 && _action$meta.isLightUpdate) {
        state.entities[drawId].currentNetSale = draw.currentNetSale;
      } else {
        drawsAdapter.setOne(state, draw);
        const cacheTs = new Date().toISOString();
        state.loaded[drawId] = cacheTs;
      }
    }).addCase(loadDrawsLite.pending, (state, action) => {
      const {
        relatedProductId
      } = action.meta.arg;
      const itsProductId = products.getSpecificDrawName(relatedProductId);
      const drawKey = [itsProductId, 'lite'].join('_');
      state.requests[drawKey] = FetchState.Pending;
    }).addCase(loadDrawsLite.rejected, (state, action) => {
      const {
        relatedProductId
      } = action.meta.arg;
      const itsProductId = products.getSpecificDrawName(relatedProductId);
      const drawKey = [itsProductId, 'lite'].join('_');
      state.requests[drawKey] = FetchState.Rejected;
    }).addCase(loadDrawsLite.fulfilled, (state, action) => {
      const cacheTs = new Date().toISOString();
      const {
        productId,
        drawNumber,
        relatedProductId,
        type
      } = action.meta.arg;
      const drawKey = [productId, drawNumber].join('_');
      const itsProductId = products.getSpecificDrawName(relatedProductId);
      drawsAdapter.addMany(state, action.payload.Draws.entities);
      state.relatedDraws[drawKey] = state.relatedDraws[drawKey] || {};
      const oldRelatedDraws = state.relatedDraws[drawKey][type] || [];
      state.relatedDraws[drawKey][type] = [...new Set([...oldRelatedDraws, ...action.payload.Draws.relatedDraws[drawKey][type]])];
      const relatedDrawKey = [itsProductId, 'lite'].join('_');
      state.requests[relatedDrawKey] = FetchState.Fulfilled;
      state.loaded[relatedDrawKey] = cacheTs;
      for (const drawId of action.payload.Draws.ids) {
        state.loaded[drawId] = cacheTs;
        state.requests[drawId] = FetchState.Fulfilled;
      }
    }).addCase(loadNetSaleForDraws.fulfilled, (state, action) => {
      const draws = action.payload.Draws;
      draws.forEach(draw => {
        const drawId = "".concat(draw.productId, "_").concat(draw.drawNumber);
        state.requests[drawId] = FetchState.Fulfilled;
        state.entities[drawId].currentNetSale = draw.currentNetSale || draw.currentNetSales;
      });
    });
  }
});
setGlobal('svs.components.sport.drawsRedux', {
  slice: drawsSlice
});

 })(window);